import Link from 'next/link'
import { format } from 'date-fns'

import ResponsiveImage from '../../common/ResponsiveImage'

interface InsightCardProps {
	insight: any
}

const InsightCard = (props: InsightCardProps) => {

	const { insight } = props
	const image = insight.previewImage && insight.previewImage.length > 0 ? insight.previewImage[0] : null
	const tags = insight.tags && insight.tags.length > 0 ? insight.tags : null

	return (
		<article className="insight-card">
			<Link href={`/${insight.uri}`} className='insight-link'>
				{image ? (
					<ResponsiveImage
						image={image}
						cleanImplementation={true}
						containerClass={'insight-card__background'}
					/>
				) : (
					<div className='image-placeholder'></div>
				)}
				
				{tags && (
					<ul className="insight-card__tag-list">
						{tags.map((tag: any) => <li key={tag.id} className="insight-card__tag"><span className="tag-pill">{tag.title}</span></li>)}
					</ul>
				)}
			
				<header className='insight-card__text'>			
					<h3 className="insight-title">{insight.previewTitle ? insight.previewTitle : insight.title}</h3>
					<div className="insight-text" dangerouslySetInnerHTML={{ __html: insight.previewText }}></div>
				</header>
			</Link>
		</article>
	)

}
export default InsightCard
