import Link from 'next/link'

interface ServiceNavigationListProps {
	block: any
}

/**
 * Component for showing services in a list
 *
 * @component
 */
const ServiceNavigationList = (props: ServiceNavigationListProps) => {

	const { block } = props

	// TODO - filter out current service if displaying sibling services.
	if ( !block.selectedServices || block.selectedServices.length === 0 ) {
		return null
	}

	return (
		<section className="content-container">
			{block.heading ? (
				<header className="content-container__header-wrapper">
					<div className="content-container__header-title">
						<h2>{block.heading}</h2>
					</div>
				</header>
			) : null}
			<ul className="three-column-list">
				{block.selectedServices.map(( service: any ) => {
					return (
						<li key={service.id} className="three-column-list__item">
							<article className="product-card">
								<h3 className="product-card__title">{service.title}</h3>
								{service.shortDescription && <div className="product-card__body" dangerouslySetInnerHTML={{__html: service.shortDescription}} />}
								<p><Link href={`/${service.uri}`}>Read more →</Link></p>
							</article>
						</li>
					)
				})}
			</ul>
		</section>
	)

}
export default ServiceNavigationList
