import LinkButton from '../../common/LinkButton'

interface SectionHeaderProps {
	block: any
}

const SectionHeader = (props: SectionHeaderProps) => {

	const { block } = props

	return (
		<div key={block.id} className="section-header">
			<div className="section-header__wrapper">
				<div className="section-header__container">
					{block.heading && <h2>{block.heading}</h2>}
					{block.text && <div dangerouslySetInnerHTML={{ __html: block.text }} />}
					<LinkButton link={block.linkTo} linkClass="button button--standard" />
				</div>
			</div>
		</div>
	)

}
export default SectionHeader
