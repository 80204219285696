import React from 'react'

import Link from 'next/link'
import { format } from 'date-fns'
import ResponsiveImage from '../common/ResponsiveImage'

interface ContentContainerProps {
	heading?: string
	link?: JSX.Element | null
	modifierClass?: string
	children: React.ReactNode | null
}

const ContentContainer = (props: ContentContainerProps) => {

	const { heading, link, modifierClass, children } = props

	if ( !children ) {
		return null
	}

	return (
		<section className={`content-container ${modifierClass ? `content-container--${modifierClass}` : ''}`}>
			{heading || link ? (
				<header className="content-container__header-wrapper">
					<div className="content-container__header-title">
						{heading && <h2>{heading}</h2>}
					</div>
					{link &&
						<nav className="content-container__header-cta">
							{link}
						</nav>
					}
				</header>
			) : null}
			{children}

		</section>
	)

}
export default ContentContainer
