/* Work around to get company name for news cards etc. (site name not available on GraphQL) */
const companies:any = {
	default: {
		title: 'Arctic'
	},
	securities: {
		title: 'Arctic Securities'
	},
	aam: {
		title: 'Arctic Asset Management'
	},
	insurance: {
		title: 'Arctic Insurance'
	},
	arem: {
		title: 'Arctic Real Estate Management'
	},
	capital: {
		title: 'Arctic Capital'
	},
	shipping: {
		title: 'Arctic Shipping'
	},
	aoi: {
		title: 'Arctic Offshore International'
	},
	aaim: {
		title: 'Arctic Alternative Investments Management'
	},
	aor: {
		title: 'Arctic Offshore Rig'
	},
	ared: {
		title: 'Arctic Real Estate Development'
	},
};

export function getSiteName( siteHandle: string ) {
	const siteName = companies[siteHandle] ? companies[siteHandle].title : 'Arctic';

	return siteName
}