
import LinkButton from '../../../common/LinkButton'

interface LockUpContentHeaderProps {
	block: any
}

const LockUpContentHeader = (props: LockUpContentHeaderProps) => {

	const { block } = props

	if (block.heading || block.text) {

		return (
			<div className="lock-up__content-block">
				{block.heading && <h2>{block.heading}</h2>}
				{block.text && <span dangerouslySetInnerHTML={{ __html: block.text }} />}
				<LinkButton
					link={block.linkTo}
					linkClass="button button--standard"
					containerClass="lock-up__content-link"
				/>
			</div>
		)

	}

	return null

}
export default LockUpContentHeader
