import { useState } from 'react'

import Link from 'next/link'
import { ChevronDown } from '../../svgs/Icons'

interface CollapsibleListProps {
	block: any
}

const Question = (props: any) => {

	const { question } = props
	const [isOpen, setIsOpen] = useState(question.isOpen)

	return (
		<li className={`collapsible-list__list-item collapsible-list__list-item--is-${isOpen ? 'open' : 'closed'}`}>
			<div className='collapsible-list__list-item-inner'>
				<button className='collapsible-list__list-item-question' onClick={() => setIsOpen(!isOpen)}>
					<div className='collapsible-list__list-item-question-text'>
						<span>{question.heading}</span>
					</div>
					<div className='collapsible-list__list-item-question-icon'>
						<div className='collapsible-list__list-item-question-icon-inner'>
							<ChevronDown />
						</div>
					</div>
				</button>
				{isOpen && <div className='collapsible-list__list-item-answer' dangerouslySetInnerHTML={{ __html: question.body }} />}
			</div>
		</li>
	)

}



const CollapsibleList = (props: CollapsibleListProps) => {

	const { block } = props

	return (
		<section className='collapsible-list'>
			{block.heading && block.text &&
				<header className='collapsible-list__header'>
					<div className='collapsible-list__header-content'>
						{block.heading && <h2>{block.heading}</h2>}
						{block.text && <div dangerouslySetInnerHTML={{ __html: block.text }} />}
					</div>
				</header>
			}
			<div className='collapsible-list__wrapper'>
				<div className='collapsible-list__content'>
					<ul className='collapsible-list__list'>
						{block.children.map((item: any) => {
							return <Question key={item.id} question={item} />
						})}
					</ul>
				</div>
			</div>
		</section>
	)

}

export default CollapsibleList
