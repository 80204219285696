import ResponsiveImage from "../../common/ResponsiveImage"
import HtmlVideo from '../../common/HtmlVideo'

interface SingleMediaProps {
	media: any
	caption?: string
	size?: string
}


const SingleMediaWrapper = (props: any) => {

	const { children, caption, size } = props

	return (
		<div className={`block-single-media block-single-media--size-${size ? size : 'default'}`}>
			<div className="block-single-media__wrapper">
				<div className="block-single-media__content">
					{children}
				</div>
				{caption && <div className="block-single-media__caption" dangerouslySetInnerHTML={{ __html: caption }} />}
			</div>
		</div>
	)

}


/**
 * Components for showing image or video
 *
 * @param props
 * @returns
 */
const SingleMedia = (props: SingleMediaProps) => {

	const { media, caption, size } = props

	if (!media) {
		return null
	}

	/** embeddedAsset */
	if (media.mimeType === 'application/json') {

		// Only render videos from embeddedAsset field
		if ( media.embeddedAsset && media.embeddedAsset.type === 'video' ) {
			return (
				<SingleMediaWrapper caption={caption} size={size}>
					<div className="embed-container" dangerouslySetInnerHTML={{ __html: media.embeddedAsset.html }} />
				</SingleMediaWrapper>
			)
		// Render podcast iframes
		} else if ( media.embeddedAsset && media.embeddedAsset.type === 'rich' ) {
			return (
				<SingleMediaWrapper caption={null} size={null}>
					<div className="podcasts-list__content-media" dangerouslySetInnerHTML={{ __html: media.embeddedAsset.html }} />
				</SingleMediaWrapper>
			)
		}

		return null

	}

	// TODO - add more generic check for videos
	if ( media.mimeType === 'video/mp4' ) {
		return (
			<SingleMediaWrapper caption={caption} size={size}>
				<HtmlVideo url={media.url} />
			</SingleMediaWrapper>
		)


	}

	return (
		<SingleMediaWrapper caption={caption} size={size}>
			<ResponsiveImage image={media} />
		</SingleMediaWrapper >
	)

}
export default SingleMedia
