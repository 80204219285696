import { useState, useEffect } from 'react'
import Link from 'next/link'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { getSiteName } from '../../../lib/helpers/sites';

interface NewsCardProps {
	news: any
	hideCompany?: boolean
}

const NewsCard = (props: NewsCardProps) => {

	const { news, hideCompany } = props
	const [formattedDate, setFormattedDate] = useState('')

	useEffect(() => {
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const localDate = utcToZonedTime(new Date(news.postDate), timeZone)
		setFormattedDate(format(localDate, 'dd MMMM yyyy'))
	}, [news.postDate])

	const siteName = getSiteName(news.siteHandle);

	const articleDate = hideCompany ? `${formattedDate}` : `${siteName} - ${formattedDate}`;

	return (
		<article className="news-card">
			{news.postDate && <span className="news-card__date">{articleDate}</span>}
			<h3 className="news-card__title"><Link href={`/${news.uri}`}>{news.title}</Link></h3>
			{/* @TODO Show leadParagraph if previewText empty ? */}
			{news.previewText && <div className="news-card__body" dangerouslySetInnerHTML={{ __html: news.previewText }} />}

			<div className="button-container">
				<Link href={`/${news.uri}`} className='button button--inverted'>Read more <span className="arrow">→</span></Link>
			</div>
		</article>
	)

}
export default NewsCard
