import ResponsiveImage from '../../../common/ResponsiveImage'

interface LockUpWrapperProps {
	block: any
	children: any
}

const LockUpWrapper = (props: LockUpWrapperProps) => {

	const { block, children } = props
	const mediaType = block.image && block.image.length > 0 ? block.image[0].kind : null;
	const asset = block.image && block.image.length > 0 ? block.image[0] : null;

	const MediaElement = () => {
		if (mediaType && mediaType === 'image') {
			return <ResponsiveImage
					image={asset}
					containerClass={`lock-up__media-container`}
					cleanImplementation={true}
				/>
		} else if (mediaType && mediaType === 'video') {
			return (
				<div className="lock-up__media-container">
					<video muted playsInline loop autoPlay>
						<source src={asset.url} type={`video/mp4`} />
					</video>
				</div>
			)
		} else if (mediaType === 'json') {
			// Only render videos from embeddedAsset field
			if ( asset.embeddedAsset && asset.embeddedAsset.type === 'video' ) {
				return (
					<div className="lock-up__media-embed-container">
						<div className="embed-wrapper">
							<div className="embed-container" dangerouslySetInnerHTML={{ __html: asset.embeddedAsset.html }} />
						</div>
					</div>

				)
			}
		} else {
			return null;
		}
	}
	return (
		<div className={`lock-up lock-up--image-align-${block.imageAlign ? block.imageAlign : 'left'}`}>
			<div className="lock-up__wrapper">
				<div className="lock-up__media">
					<MediaElement />
				</div>
				<div className="lock-up__content">
					{children}
				</div>
			</div>
		</div>
	)

}
export default LockUpWrapper
