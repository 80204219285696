import Link from 'next/link'

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";

import DealCard from './partials/DealCard';

interface FeaturedDealsProps {
	block: any
	deals?: any
	background?: string
}


// TODO - fix pagination styles


/**
 * Component presenting featured deals in a gallery
 *
 * @component
 */
const FeaturedDeals = (props: FeaturedDealsProps) => {

	const { block, deals, background } = props
	const selectedDeals = deals && deals.length > 0 ? deals : block && block.selectedDeals && block.selectedDeals.length > 0 ? block.selectedDeals : null

	if (!selectedDeals) {
		return null
	}

	return (
		<div className={`featured-deals ${background && background == 'white' ? 'featured-deals--white' : 'featured-deals--blue'}`}>
			{block && block.heading &&
				<div className="featured-deals__header">
					<div className="featured-deals__header-content">
						<h2>{block.heading}</h2>
					</div>
					{block.addArchiveLink &&
						<div className="featured-deals__header-link desktop">
							<Link href="/deals" className='button button--on-dark'>View all deals →</Link>
						</div>
					}
				</div>
			}
			<Swiper
				speed={1000}
				loop={true}
				slidesPerView={1}
				autoHeight={false}
				autoplay={{
					delay: 5000
				}}
				pagination={{
					clickable: true
				}}
				modules={[Autoplay, Pagination]}
				className="featured-deals__deal-container"
			>
				{selectedDeals.map((deal: any, i: number) => {
					return (
						<SwiperSlide key={deal.id}>
							<DealCard deal={deal} />
						</SwiperSlide>
					)
				})}
			</Swiper>

			{block && block.addArchiveLink &&
				<div className="featured-deals__header-link mobile">
					<Link href="/deals" className='button button--on-dark'> → View all deals</Link>
				</div>
			}
		</div>
	)

}
export default FeaturedDeals
