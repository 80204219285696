
const SectionDivider = () => {

	return (
		<div className="section-divider">
			<div className="section-divider__wrapper">
				<div className="section-divider__container">
					<div className="section-divider__border" />
				</div>
			</div>
		</div>
	)

}
export default SectionDivider
