import Link from 'next/link'
import LinkButton from '../../common/LinkButton'

interface TwoColumnTextProps {
	block: any
}

const TwoColumnText = (props: TwoColumnTextProps) => {

	const { block } = props

	return (
		<div key={block.id} className="two-column-text">
			<div className="two-column-text__wrapper">
				<div className="two-column-text__header">
					{block.heading && <h2>{block.heading}</h2>}
				</div>
				<div className="two-column-text__content">
					{block.text && <div className="two-column-text__content-inner" dangerouslySetInnerHTML={{ __html: block.text }} />}
					<LinkButton link={block.linkTo} />
				</div>
			</div>
		</div>
	)

}
export default TwoColumnText
