import { format } from 'date-fns'
import ResponsiveImage from '../../../common/ResponsiveImage'

interface DealCardProps {
	deal: any
}

const DealCard = (props: DealCardProps) => {

	const { deal } = props

	if (!deal) {
		return null
	}

	// TODO - add logo as fallback image?
	// maybe filter out deals without images?
	const image = deal.previewImage && deal.previewImage.length > 0 ? deal.previewImage[0] : null
	// Default currency code to prevent error message
	const currencyCode = deal.dealCurrency ? deal.dealCurrency : 'NOK';

	return (
		<div className={`deal-card`}>
			<div className="deal-card__wrapper">
				<ResponsiveImage
					image={image}
					containerClass="deal-card__media"
					cleanImplementation={true}
				/>
				<div className="deal-card__body">
					<span className="deal-card__date">{format(new Date(deal.postDate), 'MMMM yyyy')}</span>
					<h3 className="deal-card__title"><strong>{deal.company} — </strong>{deal.previewTitle ? deal.previewTitle : deal.title}</h3>
					<p className="deal-card__price">{deal.dealAmount > 0 ? new Intl.NumberFormat('en', {
						style: 'currency',
						currency: currencyCode,
						notation: 'compact',
						compactDisplay: 'long'
					}).format(deal.dealAmount) : 'Undisclosed'}</p>
				</div>
			</div>
		</div>
	)

}
export default DealCard
