import Link from 'next/link'

import LockUpWrapper from './partials/LockUpWrapper'
import LockUpContentHeader from './partials/LockUpContentHeader'
import LinkButton from '../../common/LinkButton'

interface LockUpBasicProps {
	block: any
}

const LockUpBasic = (props: LockUpBasicProps) => {

	const { block } = props

	return (
		<LockUpWrapper block={block}>
			<>
				<LockUpContentHeader block={block} />
				{block.simpleContentBlocks.map((content: any) => {
					return (
						<div key={content.id} className="lock-up__content-block">
							{content.heading && <h3>{content.heading}</h3>}
							{content.text && <span dangerouslySetInnerHTML={{ __html: content.text }} />}
							<LinkButton
								link={content.linkTo}
								linkClass="button button--inverted"
								containerClass="lock-up__content-link"
							/>
						</div>
					)
				})}
			</>
		</LockUpWrapper>
	)

}
export default LockUpBasic
