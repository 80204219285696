import Link from "next/link"
import ResponsiveImage from "../../common/ResponsiveImage"
import ContentContainer from "../ContentContainer"

import InsightCard from '../insight/InsightCard'

interface InsightsGridProps {
	block: any
}


const InsightsGrid = (props: InsightsGridProps) => {

	const { block } = props
	const insights = block.selectedInsights && block.selectedInsights.length > 0 ? block.selectedInsights : null

	if (!insights) {
		return null
	}

	// TODO - improve render logics
	return (
		<ContentContainer
			heading={block.heading}
			link={block.addArchiveLink ? <Link href="/insights" className='button button--inverted insights-link-desktop'>→ All insights</Link> : null}
			modifierClass="insights-module"
		>
			<div className="insights-grid">
				<div className="insights-grid__wrapper">
					<div className="insights-grid__content">
						<div className="insights-grid__inner">

							<div className="insights-grid__item">
								<InsightCard insight={insights[0]} />
							</div>
							<div className="insights-grid__item">
								{insights.length > 1 && <InsightCard insight={insights[1]} />}
							</div>
							<div className="insights-grid__item">
								{insights.length > 2 && <InsightCard insight={insights[2]} />}
							</div>

						</div>
					</div>
					{block.addArchiveLink ? <Link href="/insights" className='button button--inverted insights-link-mobile'>→ All insights</Link> : null}
				</div>
			</div>
		</ContentContainer>
	)

}
export default InsightsGrid
