import Link from 'next/link'
import { format } from 'date-fns'
import NewsCard from '../news/NewsCard'
import ContentContainer from '../ContentContainer'

interface LatestNewsProps {
	block?: {
		heading?: any
		link?: any
	}
	heading?: string
	addArchiveLink?: boolean
	news?: any
	hideCompany?: boolean
}

const LatestNews = (props: LatestNewsProps) => {

	const { heading, addArchiveLink, news, hideCompany } = props

	if ( !news || news.length === 0 ) {
		return null
	}

	return (
		<ContentContainer
			heading={heading}
			link={addArchiveLink ? <Link href="/news" className='button button--inverted'>View all news →</Link> : null}
		>
			{news?.length > 0 ? (
				<ol className="three-column-list">
					{news.map((item: any) => {
						return (
							<li key={item.id} className="three-column-list__item">
								<NewsCard news={item} hideCompany={hideCompany} />
							</li>
						)
					})}
				</ol>
			) : (
				<ol className="three-column-list">
					<li className="three-column-list__item">
						<p>List is empty</p>
					</li>
				</ol>
			)}
		</ContentContainer>
	)

}
export default LatestNews
