import { useState, useEffect } from 'react'

import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

interface HtmlVideoProps {
	url: any
}

const HtmlVideo = (props: HtmlVideoProps) => {

	const { url } = props

	const [ isRequested, setIsRequested ] = useState(false)
	const { ref, inView, entry } = useInView({
		threshold: 0,
	})

	useEffect(() => {
		if ( inView && !isRequested ) {
			setIsRequested(true)
		}
	}, [ inView, isRequested ])

	if ( !url ) {
		return null
	}

	return (
		<div
			ref={ref}
			style={{
				position: 'relative',
				paddingBottom: '56.25%',
				overflow: 'hidden',
				width: '100%'
			}}
		>
			{isRequested &&
				<video
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: 'auto'
					}}
					muted playsInline loop autoPlay
				>
					<source src={url} type={`video/mp4`} />
				</video>
			}
		</div>
	)



}

export default HtmlVideo
